import React from "react";
import "reset-css";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import { sortBy } from "lodash";
import classnames from "classnames";
import Nav from "../components/nav";
import styles from "./about.module.scss";
import Layout from "../components/layout";
import BlacksWhoDesign from "../friends/blackswhodesign.svg";
import LatinxsWhoDesign from "../friends/latinxswhodesign.png";
import PeopleOfCraft from "../friends/peopleofcraft.png";
import QueerDesignClub from "../friends/queerdesignclub.svg";
import IndiansWhoDesign from "../friends/indianswhodesign.svg";
import FilipinosWhoDesign from "../friends/filipinoswhodesign.png";
import APIWhoDesign from "../friends/apiwhodesign.svg";
import BraziliansWhoDesign from "../friends/brazilianswhodesign.svg";
import Button from "../components/button";

const friends = [
  {
    title: "Blacks Who Design",
    link: "https://blackswho.design/",
    image: BlacksWhoDesign,
  },
  {
    title: "Latinxs Who Design",
    link: "https://latinxswhodesign.com",
    image: LatinxsWhoDesign,
    invert: true,
  },
  {
    title: "Queer Design Club",
    link: "https://queerdesign.club/",
    image: QueerDesignClub,
    invert: true,
  },
  {
    title: "Brazilians Who Design",
    link: "https://brazilianswho.design/",
    image: BraziliansWhoDesign,
  },
];

const sortedFriends = sortBy(friends, (friend) => friend.title);

const App = () => (
  <Layout>
    <Helmet title="About | Haitians Who Code" />
    <Nav theme="light" />
    <div className={styles.container}>
      <h1 className={styles.h1}>About this project</h1>
      {/* <p>It’s hard to be what you can’t see – this is why representation matters. We know that in our tech industry — Black, Indigenous, and Latinx — are underrepresented and there is more work to be done. We know there is undeniable power in representation.  </p> */}
      <p>
        Haitians Who Code highlights all of the inspiring Haitians in the tech industry. The goal is to inspire new technologists, encourage people to diversify their feeds, and discover amazing individuals to join your team.
      </p>

      <h2 className={styles.emphasis}>If you're of Haitian descent, this site's for you</h2>
      <p>There are amazing Haitians all accross the industry. Hopefully this project inspires yo to see yourself among the ranks.</p>

      <h2 className={styles.emphasis}>If you're not of Haitian descent, this site's for you to ☺️</h2>
      <p><strong>Reply to a recruiter:</strong> Tired of recruiting emails? Instead of hitting archive, reply with a link to this site.</p>
      <p><strong>Target your mentoring:</strong> Dedicate your lunch breaks towards mentoring people that might not normally get access to you.</p>
      <p><strong>Volunteer:</strong> Consider blocking off some time to teach tech to younger students</p>

      <h2 className={styles.emphasis}>Similar directories</h2>
      <p>
        Examining diversity along the axis of gender adn racse is one small piece of the
        puzzle. If you’ve found this site to be valuable, please take a look at
        the work of friends in the industry building similar efforts.
      </p>
      <div className={styles.friendContainer}>
        {sortedFriends.map((friend) => {
          const friendImageStyles = classnames({
            [styles.friendImage]: true,
            [styles.friendImageInvert]: friend.invert === true,
            [styles.friendImageContrast]: friend.contrast === true,
          });
          return (
            <a
              href={friend.link}
              target="_blank"
              rel="noopener noreferrer"
              key={friend.title}
              className={styles.friendItem}
            >
              <img
                src={friend.image}
                className={friendImageStyles}
                alt={`${friend.title} logo`}
              />
              <p className={styles.friendTitle}>{friend.title}</p>
            </a>
          );
        })}
      </div>
      <h1 className={styles.h1}>How to use</h1>
      <p>Here are some things Haitians Who Code can help you with:</p>

      {/* <h2 className={styles.emphasis}>
        Use{" "}
        <a
          href="https://www.proporti.onl/"
          target="_blank"
          rel="noopener noreferrer"
        >
          proporti.onl
        </a>{" "}
        to check the ratio of the people you follow on Twitter.
      </h2>
      <p>
        If you’re following more men than women, use this project to follow new
        women and diversify the voices in your feed. Be aware that a feed of
        white women is not diverse.
      </p> */}
      <h2 className={styles.emphasis}>
        If you’re a hiring manager, use this project to find candidates.{" "}
      </h2>
      <p>
        Examine the ratio of senior men to senior women in your organization.
        Are people of color equally represented? Consider hiring people of color into
        promotions above their current role.
      </p>

      <h2 className={styles.emphasis}>
        If you’re organizing a conference, use this project to find speakers.
      </h2>
      <p>
        Ensure that the women’s speaking slots are as prominent as the men’s.
        Are women of color equally represented? Consider reaching out to women
        who have never given a talk before.
      </p>

      <h2 className={styles.emphasis}>Further reading</h2>
      <p>
        For becoming a better ally – to haitians, women, people of color, LGBTQ, disabled,
        ESL or any other marginalized group.
      </p>

      <ul className={styles.ul}>
        <li className={styles.liLinks}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.guidetoallyship.com/"
          >
            Guide to Allyship
          </a>{" "}
          by Amélie Lamont
        </li>

        <li className={styles.liLinks}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://medium.com/@nmsanchez/how-to-build-inclusive-culture-360160f417a1"
          >
            How to Build Inclusive Culture
          </a>{" "}
          by Nicole Sanchez
        </li>
        <li className={styles.liLinks}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://medium.com/mule-design/be-a-pal-my-dudes-a34c06df1b1d"
          >
            Be a Pal, My Dudes
          </a>{" "}
          by Erika Hall
        </li>
        <li className={styles.liLinks}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://larahogan.me/blog/what-sponsorship-looks-like/"
          >
            What Does Sponsorship Look Like?
          </a>{" "}
          by Lara Hogan
        </li>
        <li className={styles.liLinks}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.huffingtonpost.com/entry/some-garbage-i-used-to-believe-about-equality_us_58501c5be4b0151082221ef7"
          >
            Some Garbage I Used to Believe About Equality
          </a>{" "}
          by Johnathan Nightingale
        </li>
      </ul>
      <h1 className={styles.h1}>Other notes</h1>
      <h2 className={styles.emphasis}>Support</h2>
      <p>
        Haitians Who Code is an independent project sprung out of <a href="https://devhaiti.com" target="_blank">/dev/haiti</a>. If you’re interested in
        supporting it, please consider sponsoring a young Haitian.
      </p>
      <Button
        href="https://gofundme.com"
        width="auto"
      >
        Sponsor a Young Haitian in Tech
      </Button>
      <h2 className={styles.emphasis}>Opt out</h2>
      <p>
        If you’ve been featured in the directory and you’d rather not be, please
        send a DM to{" "}
        <a
          href="https://twitter.com/devhaiti"
          target="_blank"
          rel="noopener noreferrer"
        >
          @devhaiti
        </a>{" "}
        on Twitter and you will be removed.
      </p>
      
      <br/>
      <br/>
      <br/>

      <p>
        <span role="img" aria-label="Peace hand emoji">
          ✌🏿
        </span>{" "}
        <a
          href="https://twitter.com/_darkfadr"
          target="_blank"
          rel="noopener noreferrer"
        >
          @_darkfadr
        </a>
      </p>
      <div className={styles.backContainer}>
        <Link to="/" className={styles.backLink}>
          Back to directory
        </Link>
      </div>
    </div>
  </Layout>
);

export default App;
